import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_DEPARTMENTS_CALL,
	GET_DEPARTMENTS_OWNED_DEPARTMENT_CALL,
	GET_DEPARTMENTS_LEARNING_RECORDS_CALL,
	GET_DEPARTMENTS_EMPLOYEES_CALL,
} from '@src/store/types/departments';

import {
	getDepartmentsDone,
	getDepartmentsFail,
	getOwnedDepartmentDone,
	getOwnedDepartmentFail,
	getDepartmentsLearningRecordsDone,
	getDepartmentsLearningRecordsFail,
	getDepartmentsEmployeesDone,
	getDepartmentsEmployeesFail,
} from '@src/store/actions/departments';
import {
	getDepartment,
	getOwnedDepartment,
	getDepartmentsLearningRecords,
	getDepartmentsEmployees,
} from '@src/services/departments';

export const getDepartmentsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_DEPARTMENTS_CALL),
	mergeMap(
		({ payload }) => fromPromise(getDepartment(payload)).pipe(
			switchMap(res => of(getDepartmentsDone({ res }))),
			catchError(error => of(getDepartmentsFail({ error }))),
		),
	),
);

export const getOwnedDepartmentEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_DEPARTMENTS_OWNED_DEPARTMENT_CALL),
	mergeMap(
		({ payload }) => fromPromise(getOwnedDepartment(payload)).pipe(
			switchMap(res => of(getOwnedDepartmentDone({ res }))),
			catchError(error => of(getOwnedDepartmentFail({ error }))),
		),
	),
);

export const getDepartmentsLearningRecordsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_DEPARTMENTS_LEARNING_RECORDS_CALL),
	mergeMap(
		({ payload }) => fromPromise(getDepartmentsLearningRecords(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(getDepartmentsLearningRecordsDone({ res, isDownload: payload.isDownload }));
			}),
			catchError(error => of(getDepartmentsLearningRecordsFail({ error }))),
		),
	),
);

export const getDepartmentsEmployeesEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_DEPARTMENTS_EMPLOYEES_CALL),
	mergeMap(
		({ payload }) => fromPromise(getDepartmentsEmployees(payload)).pipe(
			switchMap(res => of(getDepartmentsEmployeesDone({ res }))),
			catchError(error => of(getDepartmentsEmployeesFail({ error }))),
		),
	),
);
