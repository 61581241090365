import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
	GET_DEPARTMENTS_CALL,
	GET_DEPARTMENTS_DONE,
	GET_DEPARTMENTS_FAIL,
	GET_DEPARTMENTS_OWNED_DEPARTMENT_CALL,
	GET_DEPARTMENTS_OWNED_DEPARTMENT_DONE,
	GET_DEPARTMENTS_OWNED_DEPARTMENT_FAIL,
	GET_DEPARTMENTS_LEARNING_RECORDS_CALL,
	GET_DEPARTMENTS_LEARNING_RECORDS_DONE,
	GET_DEPARTMENTS_LEARNING_RECORDS_FAIL,
	GET_DEPARTMENTS_EMPLOYEES_CALL,
	GET_DEPARTMENTS_EMPLOYEES_DONE,
	GET_DEPARTMENTS_EMPLOYEES_FAIL,
} from '@src/store/types';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	departments: [],
	getDepartmentsSideEffect: defaultSideEffectState,
	ownedDepartments: {
		data: [],
		pagination: {
			totalCount: 0,
		},
	},
	getOwnedDepartmentsSideEffect: defaultSideEffectState,
	departmentsLearningRecords: {
		data: [],
		pagination: {
			totalCount: 0,
		},
	},
	getDepartmentsLearningRecordsSideEffect: defaultSideEffectState,
	departmentsEmployees: {
		data: [],
		pagination: {
			totalCount: 0,
		},
	},
	getDepartmentsEmployeesSideEffect: defaultSideEffectState,
};

export default handleActions({
	[GET_DEPARTMENTS_CALL](state) {
		return update(state, {
			getDepartmentsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_DEPARTMENTS_DONE](state, { payload: { res } }) {
		return update(state, {
			departments: { $set: res },
			getDepartmentsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_DEPARTMENTS_FAIL](state, { payload }) {
		return update(state, {
			getDepartmentsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
	[GET_DEPARTMENTS_OWNED_DEPARTMENT_CALL](state) {
		return update(state, {
			getOwnedDepartmentsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_DEPARTMENTS_OWNED_DEPARTMENT_DONE](state, { payload: { res } }) {
		return update(state, {
			ownedDepartments: { $set: res },
			getOwnedDepartmentsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_DEPARTMENTS_OWNED_DEPARTMENT_FAIL](state, { payload }) {
		return update(state, {
			getOwnedDepartmentsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
	[GET_DEPARTMENTS_LEARNING_RECORDS_CALL](state) {
		return update(state, {
			getDepartmentsLearningRecordsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_DEPARTMENTS_LEARNING_RECORDS_DONE](state, { payload: { res, isDownload } }) {
		return update(state, {
			...!isDownload ? {
				departmentsLearningRecords: { $set: res },
			} : {},
			getDepartmentsLearningRecordsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_DEPARTMENTS_LEARNING_RECORDS_FAIL](state, { payload }) {
		return update(state, {
			getDepartmentsLearningRecordsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_DEPARTMENTS_EMPLOYEES_CALL](state) {
		return update(state, {
			getDepartmentsEmployeesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_DEPARTMENTS_EMPLOYEES_DONE](state, { payload: { res } }) {
		return update(state, {
			departmentsEmployees: { $set: res },
			getDepartmentsEmployeesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_DEPARTMENTS_EMPLOYEES_FAIL](state, { payload }) {
		return update(state, {
			getDepartmentsEmployeesSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
}, initialState);
