import { createStore, applyMiddleware, combineReducers } from 'redux';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';

import updateHydrateState from '@src/router/hydrate';

import rootEpic from './epics';
import rootReducer from './reducers';

const bindMiddleware = middleware => {
	if (process.env.NODE_ENV !== 'production') {
		return composeWithDevTools(applyMiddleware(...middleware));
	}
	return applyMiddleware(...middleware);
};

const reducer = (state, action) => {
	if (action.type === HYDRATE) {
		const nextState = updateHydrateState(state, action.payload);

		if (state.count) nextState.count = state.count; // preserve count value on client side navigation
		return nextState;
	} else {
		return combineReducers(rootReducer)(state, action);
	}
};

const initStore = () => {
	const epicMiddleware = createEpicMiddleware();

	const store = createStore(
		reducer,
		bindMiddleware([
			epicMiddleware,
			// createLogger({ predicate: () => process.env.NODE_ENV === 'development' }),
		]),
	);

	epicMiddleware.run(rootEpic);

	return store;
};

export default createWrapper(initStore);
