// eslint-disable-next-line import/no-cycle
import api from '@src/api';
import { Observable } from 'rxjs';
import { Action } from 'redux-actions';

export const getLeaveApplications = (payload: {
	year: number;
	within: string;
	status: string;
}): Observable<Action> => (
	api.get('leave-applications/', payload)
);

export const postLeaveApplication = (payload: {
	start_at: string;
	end_at: string;
	leave_type: string;
	reason: string;
	attachment_ids: string[];
}): Observable<Action> => (
	api.post('leave-applications/', payload)
);

export const getLeaveApplicationInfo = (payload: { date: string }): Observable<Action> => (
	api.get('leave-applications/info', payload)
);

export const getLeaveApplicationDetail = (payload: { id: string }): Observable<Action> => (
	api.get(`leave-applications/${payload.id}`)
);

export const postLeaveApplicationApprove = (payload: {
	id: string;
	comments: string;
  is_approved: boolean;
}): Observable<Action> => (
	api.post(`leave-applications/${payload.id}/approve`, payload)
);

export const getLeaveApplicationConfirm = (payload: { id: string }): Observable<Action> => (
	api.get(`leave-applications/${payload.id}/confirm`)
);

export const postLeaveApplicationConfirm = (payload: {
	id: string;
	confirmed_start_at: string;
	confirmed_end_at: string
}): Observable<Action> => (
	api.post(`leave-applications/${payload.id}/confirm`, payload)
);

export const postLeaveApplicationWithdraw = (payload: { id: string }): Observable<Action> => (
	api.post(`leave-applications/${payload.id}/withdraw`)
);

export const getLeaveApplicationsApproverView = (payload: {
	year: number;
	within: string;
	status: string;
	waiting_only: boolean
}): Observable<Action> => (
	api.get('leave-applications/approver_view', payload)
);

export const postLeaveApplicationWithdrawApproval = (payload: { id: string }): Observable<Action> => (
	api.post(`leave-applications/${payload.id}/withdraw_approval`)
);

export const deleteLeaveApplicationAttachment = (payload: { id: string }): Observable<Action> => (
	api['delete'](`leave-applications/attachments/${payload.id}`)
);

export const getAvailableLeaveTypes = (payload: {
	start_at: string;
	end_at: string;
}): Observable<Action> => (
	api.get('leave-entitlements/front-desk/available-leave-types', payload)
);

export const getLeaveApplicationsTotalMinutes = (payload: {
	start_at: string;
	end_at: string;
	leave_type: string;
	user_id?: string;
}): Observable<Action> => (
	api.get('leave-applications/total_minutes', payload)
);

export const getLeaveEntitlement = (): Observable<Action> => (
	api.get('leave-entitlements/front-desk')
);

export const getLeaveEntitlementByLeaveType = (payload: { leave_type: string }): Observable<Action> => (
	api.get(`/leave-entitlements/front-desk/leave-types/${payload.leave_type}`, payload)
);

export const getLeaveApplicationAttachmentDownloadLink = (payload: { id: string }): Observable<Action> => (
	api.get(`leave-applications/attachments/${payload.id}/download_link`)
);

export const getLeaveApplicationsInfoByLeaveType = (payload: { leaveType: string }): Observable<Action> => (
	api.get(`leave-applications/info/${payload.leaveType}`, payload)
);

export const getCustomLeaveTypeRules = (): Observable<Action> => (
	api.get('time-off-rule-sets/special-custom-leave-type-rules/mine')
);

export const postLeaveApplicationsGrantNewEntitlement = (payload: {
	leave_type: string;
	amount: number;
}): Observable<Action> => (
	api.post('leave-applications/grant-new-entitlement', payload)
);
