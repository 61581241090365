// eslint-disable-next-line import/no-cycle
import api from '@src/api';
import { Observable } from 'rxjs';
import { Action } from 'redux-actions';

export const getAttendanceHistory = (payload: {
	year: number;
	month: number;
}): Observable<Action> => (
	api.get('attendance-history/', payload)
	// api.mock(100, [
	// 	{
	// 		date: '02/17',
	// 		shifts: [
	// 			{
	// 				is_holiday: false,
	// 				leave_applications: [],
	// 				make_up_punch_applications: [],
	// 				off_duty: {
	// 					id: 'string',
	// 					ip_or_location_name: '台北辦公室',
	// 					is_from_make_up: true,
	// 					make_up_application_id: '01J2ZKYV2J50SA7KQAVW0WD6FY',
	// 					off_duty_early: 30,
	// 					punch_at: '18:38',
	// 					punch_in_type: 'ip',
	// 				},
	// 				on_duty: {
	// 					id: 'string',
	// 					ip_or_location_name: '台北辦公室',
	// 					is_from_make_up: true,
	// 					make_up_application_id: '01J2ZKYV2J50SA7KQAVW0WD6FY',
	// 					on_duty_late: 30,
	// 					punch_at: '09:08',
	// 					punch_in_type: 'ip',
	// 				},
	// 				other_errors: null,
	// 				overtime_applications: [],
	// 				shift_id: '123123123',
	// 				shift_name: '測試班別',
	// 			},
	// 		],
	// 	},
	// ])
);

export const deletePunchInRecord = (payload: {
	id: number;
}): Observable<Action> => (
	api['delete'](`punch-in-records/${payload.id}`)
);
