import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
	GET_SHIFT_CALL,
	GET_SHIFT_DONE,
	GET_SHIFT_FAIL,
	POST_SHIFT_CALL,
	POST_SHIFT_DONE,
	POST_SHIFT_FAIL,
	DELETE_SHIFT_CALL,
	DELETE_SHIFT_DONE,
	DELETE_SHIFT_FAIL,
	GET_SHIFT_DETAIL_CALL,
	GET_SHIFT_DETAIL_DONE,
	GET_SHIFT_DETAIL_FAIL,
	PATCH_SHIFT_CALL,
	PATCH_SHIFT_DONE,
	PATCH_SHIFT_FAIL,
	GET_SHIFT_SCHEDULERS_SETTINGS_CALL,
	GET_SHIFT_SCHEDULERS_SETTINGS_DONE,
	GET_SHIFT_SCHEDULERS_SETTINGS_FAIL,
	POST_SHIFT_SCHEDULERS_SETTINGS_CALL,
	POST_SHIFT_SCHEDULERS_SETTINGS_DONE,
	POST_SHIFT_SCHEDULERS_SETTINGS_FAIL,
	GET_SHIFT_SCHEDULES_CALL,
	GET_SHIFT_SCHEDULES_DONE,
	GET_SHIFT_SCHEDULES_FAIL,
	POST_SHIFT_SCHEDULES_CALL,
	POST_SHIFT_SCHEDULES_DONE,
	POST_SHIFT_SCHEDULES_FAIL,
	DELETE_SHIFT_SCHEDULES_CALL,
	DELETE_SHIFT_SCHEDULES_DONE,
	DELETE_SHIFT_SCHEDULES_FAIL,
	GET_SHIFT_SCHEDULES_DETAIL_CALL,
	GET_SHIFT_SCHEDULES_DETAIL_DONE,
	GET_SHIFT_SCHEDULES_DETAIL_FAIL,
	PATCH_SHIFT_SCHEDULES_CALL,
	PATCH_SHIFT_SCHEDULES_DONE,
	PATCH_SHIFT_SCHEDULES_FAIL,
	POST_SHIFT_SCHEDULES_ASSIGNMENTS_CALL,
	POST_SHIFT_SCHEDULES_ASSIGNMENTS_DONE,
	POST_SHIFT_SCHEDULES_ASSIGNMENTS_FAIL,
	GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_CALL,
	GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_DONE,
	GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_FAIL,
	GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_CALL,
	GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_DONE,
	GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_FAIL,
	GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL,
	GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_DONE,
	GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_FAIL,
	POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL,
	POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_DONE,
	POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_FAIL,
	GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_CALL,
	GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_DONE,
	GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_FAIL,
	POST_SHIFT_SCHEDULES_PUBLISH_CALL,
	POST_SHIFT_SCHEDULES_PUBLISH_DONE,
	POST_SHIFT_SCHEDULES_PUBLISH_FAIL,
	GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_CALL,
	GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_DONE,
	GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_FAIL,
	GET_MEMBERS_CALL,
	GET_MEMBERS_DONE,
	GET_MEMBERS_FAIL,
} from '@src/store/types';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	shifts: [],
	getShiftSideEffect: defaultSideEffectState,
	postShift: {},
	postShiftSideEffect: defaultSideEffectState,
	deleteShift: {},
	deleteShiftSideEffect: defaultSideEffectState,
	shiftDetail: {},
	getShiftDetailSideEffect: defaultSideEffectState,
	shiftSchedulesDetail: {},
	getShiftSchedulesDetailSideEffect: defaultSideEffectState,
	patchShift: {},
	patchShiftSideEffect: defaultSideEffectState,
	getShiftSchedulersSettings: {
		data: [],
		pagination: {
			totalCount: 0,
		},
	},
	getShiftSchedulersSettingsSideEffect: defaultSideEffectState,
	postShiftSchedulersSettings: {},
	postShiftSchedulersSettingsSideEffect: defaultSideEffectState,
	getShiftSchedules: [],
	getShiftSchedulesSideEffect: defaultSideEffectState,
	postShiftSchedules: {},
	postShiftSchedulesSideEffect: defaultSideEffectState,
	deleteShiftSchedules: {},
	deleteShiftSchedulesSideEffect: defaultSideEffectState,
	patchShiftSchedules: {},
	patchShiftSchedulesSideEffect: defaultSideEffectState,
	postShiftSchedulesAssignments: {},
	postShiftSchedulesAssignmentsSideEffect: defaultSideEffectState,
	getShiftSchedulesMyShiftsAssignments: [],
	getShiftSchedulesMyShiftsAssignmentsSideEffect: defaultSideEffectState,
	getShiftSchedulesMyShiftsLeaveApplications: [],
	getShiftSchedulesMyShiftsLeaveApplicationsSideEffect: defaultSideEffectState,
	getShiftSchedulesAssignmentsDraft: {},
	getShiftSchedulesAssignmentsDraftSideEffect: defaultSideEffectState,
	postShiftSchedulesAssignmentsDraft: {},
	postShiftSchedulesAssignmentsDraftSideEffect: defaultSideEffectState,
	getShiftSchedulesLeaveApplications: [],
	getShiftSchedulesLeaveApplicationsSideEffect: defaultSideEffectState,
	postShiftSchedulesPublish: {},
	postShiftSchedulesPublishSideEffect: defaultSideEffectState,
	getShiftSchedulesShiftEmployees: [],
	getShiftSchedulesShiftEmployeesSideEffect: defaultSideEffectState,
	getMembers: [],
	getMembersSideEffect: defaultSideEffectState,
};

export default handleActions({
	[GET_SHIFT_CALL](state) {
		return update(state, {
			getShiftSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_SHIFT_DONE](state, { payload: { res } }) {
		return update(state, {
			shifts: { $set: res },
			getShiftSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_SHIFT_FAIL](state, { payload }) {
		return update(state, {
			getShiftSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_SHIFT_CALL](state) {
		return update(state, {
			postShiftSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_SHIFT_DONE](state, { payload: { res } }) {
		return update(state, {
			postShift: { $set: res },
			postShiftSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_SHIFT_FAIL](state, { payload }) {
		return update(state, {
			postShiftSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[DELETE_SHIFT_CALL](state) {
		return update(state, {
			deleteShiftSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[DELETE_SHIFT_DONE](state, { payload: { res } }) {
		return update(state, {
			deleteShift: { $set: res },
			deleteShiftSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[DELETE_SHIFT_FAIL](state, { payload }) {
		return update(state, {
			deleteShiftSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_SHIFT_DETAIL_CALL](state) {
		return update(state, {
			getShiftDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_SHIFT_DETAIL_DONE](state, { payload: { res } }) {
		return update(state, {
			shiftDetail: { $set: res },
			getShiftDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},

	[GET_SHIFT_DETAIL_FAIL](state, { payload }) {
		return update(state, {
			getShiftDetailSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[PATCH_SHIFT_CALL](state) {
		return update(state, {
			patchShiftSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[PATCH_SHIFT_DONE](state, { payload: { res } }) {
		return update(state, {
			patchShift: { $set: res },
			patchShiftSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[PATCH_SHIFT_FAIL](state, { payload }) {
		return update(state, {
			patchShiftSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_SHIFT_SCHEDULERS_SETTINGS_CALL](state) {
		return update(state, {
			getShiftSchedulersSettingsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_SHIFT_SCHEDULERS_SETTINGS_DONE](state, { payload: { res } }) {
		return update(state, {
			getShiftSchedulersSettings: { $set: res },
			getShiftSchedulersSettingsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_SHIFT_SCHEDULERS_SETTINGS_FAIL](state, { payload }) {
		return update(state, {
			getShiftSchedulersSettingsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_SHIFT_SCHEDULERS_SETTINGS_CALL](state) {
		return update(state, {
			postShiftSchedulersSettingsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_SHIFT_SCHEDULERS_SETTINGS_DONE](state, { payload: { res } }) {
		return update(state, {
			postShiftSchedulersSettings: { $set: res },
			postShiftSchedulersSettingsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_SHIFT_SCHEDULERS_SETTINGS_FAIL](state, { payload }) {
		return update(state, {
			postShiftSchedulersSettingsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_SHIFT_SCHEDULES_CALL](state) {
		return update(state, {
			getShiftSchedulesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_SHIFT_SCHEDULES_DONE](state, { payload: { res } }) {
		return update(state, {
			getShiftSchedulersSettings: { $set: res },
			getShiftSchedulesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_SHIFT_SCHEDULES_FAIL](state, { payload }) {
		return update(state, {
			getShiftSchedulesSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_SHIFT_SCHEDULES_CALL](state) {
		return update(state, {
			postShiftSchedulesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_SHIFT_SCHEDULES_DONE](state, { payload: { res } }) {
		return update(state, {
			postShiftSchedules: { $set: res },
			postShiftSchedulesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_SHIFT_SCHEDULES_FAIL](state, { payload }) {
		return update(state, {
			postShiftSchedulesSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[DELETE_SHIFT_SCHEDULES_CALL](state) {
		return update(state, {
			deleteShiftSchedulesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[DELETE_SHIFT_SCHEDULES_DONE](state, { payload: { res } }) {
		return update(state, {
			deleteShiftSchedules: { $set: res },
			deleteShiftSchedulesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[DELETE_SHIFT_SCHEDULES_FAIL](state, { payload }) {
		return update(state, {
			deleteShiftSchedulesSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_SHIFT_SCHEDULES_DETAIL_CALL](state) {
		return update(state, {
			getShiftSchedulesDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_SHIFT_SCHEDULES_DETAIL_DONE](state, { payload: { res } }) {
		return update(state, {
			shiftSchedulesDetail: { $set: res },
			getShiftSchedulesDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_SHIFT_SCHEDULES_DETAIL_FAIL](state, { payload }) {
		return update(state, {
			getShiftSchedulesDetailSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[PATCH_SHIFT_SCHEDULES_CALL](state) {
		return update(state, {
			patchShiftSchedulesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[PATCH_SHIFT_SCHEDULES_DONE](state, { payload: { res } }) {
		return update(state, {
			patchShiftSchedules: { $set: res },
			patchShiftSchedulesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[PATCH_SHIFT_SCHEDULES_FAIL](state, { payload }) {
		return update(state, {
			patchShiftSchedulesSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_SHIFT_SCHEDULES_ASSIGNMENTS_CALL](state) {
		return update(state, {
			postShiftSchedulesAssignmentsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_SHIFT_SCHEDULES_ASSIGNMENTS_DONE](state, { payload: { res } }) {
		return update(state, {
			postShiftSchedulesAssignments: { $set: res },
			postShiftSchedulesAssignmentsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_SHIFT_SCHEDULES_ASSIGNMENTS_FAIL](state, { payload }) {
		return update(state, {
			postShiftSchedulesAssignmentsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_CALL](state) {
		return update(state, {
			getShiftSchedulesMyShiftsAssignmentsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_DONE](state, { payload: { res } }) {
		return update(state, {
			getShiftSchedulesMyShiftsAssignments: { $set: res },
			getShiftSchedulesMyShiftsAssignmentsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_FAIL](state, { payload }) {
		return update(state, {
			getShiftSchedulesMyShiftsAssignmentsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_CALL](state) {
		return update(state, {
			getShiftSchedulesMyShiftsLeaveApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_DONE](state, { payload: { res } }) {
		return update(state, {
			getShiftSchedulesMyShiftsLeaveApplications: { $set: res },
			getShiftSchedulesMyShiftsLeaveApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_FAIL](state, { payload }) {
		return update(state, {
			getShiftSchedulesMyShiftsLeaveApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL](state) {
		return update(state, {
			getShiftSchedulesAssignmentsDraftSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_DONE](state, { payload: { res } }) {
		return update(state, {
			getShiftSchedulesAssignmentsDraft: { $set: res },
			getShiftSchedulesAssignmentsDraftSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_FAIL](state, { payload }) {
		return update(state, {
			getShiftSchedulesAssignmentsDraftSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL](state) {
		return update(state, {
			postShiftSchedulesAssignmentsDraftSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_DONE](state, { payload: { res } }) {
		return update(state, {
			postShiftSchedulesAssignmentsDraft: { $set: res },
			postShiftSchedulesAssignmentsDraftSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_FAIL](state, { payload }) {
		return update(state, {
			postShiftSchedulesAssignmentsDraftSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_CALL](state) {
		return update(state, {
			getShiftSchedulesLeaveApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_DONE](state, { payload: { res } }) {
		return update(state, {
			getShiftSchedulesLeaveApplications: { $set: res },
			getShiftSchedulesLeaveApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},

	[GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_FAIL](state, { payload }) {
		return update(state, {
			getShiftSchedulesLeaveApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_SHIFT_SCHEDULES_PUBLISH_CALL](state) {
		return update(state, {
			postShiftSchedulesPublishSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_SHIFT_SCHEDULES_PUBLISH_DONE](state, { payload: { res } }) {
		return update(state, {
			postShiftSchedulesPublish: { $set: res },
			postShiftSchedulesPublishSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_SHIFT_SCHEDULES_PUBLISH_FAIL](state, { payload }) {
		return update(state, {
			postShiftSchedulesPublishSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_CALL](state) {
		return update(state, {
			getShiftSchedulesShiftEmployeesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_DONE](state, { payload: { res } }) {
		return update(state, {
			getShiftSchedulesShiftEmployees: { $set: res },
			getShiftSchedulesShiftEmployeesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_FAIL](state, { payload }) {
		return update(state, {
			getShiftSchedulesShiftEmployeesSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_MEMBERS_CALL](state) {
		return update(state, {
			getMembersSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_MEMBERS_DONE](state, { payload: { res } }) {
		return update(state, {
			getMembers: { $set: res },
			getMembersSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_MEMBERS_FAIL](state, { payload }) {
		return update(state, {
			getMembersSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

}, initialState);
