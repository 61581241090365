import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_PUNCH_IN_CALL,
	POST_PUNCH_IN_RECORD_CALL,
	GET_DAY_SHIFTS_CALL,
} from '@src/store/types/punchIn';

import {
	getPunchInDone,
	getPunchInFail,
	postPunchInRecordDone,
	postPunchInRecordFail,
	getDayShiftsDone,
	getDayShiftsFail,
} from '@src/store/actions/punchIn';
import {
	getPunchIn,
	postPunchInRecord,
	getDayShifts,
} from '@src/services/punchIn';

export const getPunchInEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_PUNCH_IN_CALL),
	switchMap(
		() => fromPromise(getPunchIn()).pipe(
			switchMap(res => of(getPunchInDone({ res }))),
			catchError(error => of(getPunchInFail({ error: error?.response?.data }))),
		),
	),
);

export const postPunchInRecordEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_PUNCH_IN_RECORD_CALL),
	switchMap(
		({ payload }) => fromPromise(postPunchInRecord(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					postPunchInRecordDone({ res }),
				);
			}),
			catchError((error) => {
				if (payload.onError) payload.onError(error.response);
				return of(
					postPunchInRecordFail({ error }),
				);
			}),
		),
	),
);

export const getDayShiftsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_DAY_SHIFTS_CALL),
	switchMap(
		({ payload }) => fromPromise(getDayShifts(payload)).pipe(
			switchMap(res => of(getDayShiftsDone({ res }))),
			catchError(error => of(getDayShiftsFail({ error: error?.response?.data }))),
		),
	),
);
