import { produce } from 'immer';
import _ from 'lodash';

import * as selectors from '@src/store/selectors';
import { defaultActionCalls } from './ssr';

export const SSR_PAGE_HOME = '/training';
export const SSR_PAGE_COURSE = '/course/[courseId]';
export const SSR_PAGE_EVENT = '/event/[eventShortLink]';
export const SSR_PAGE_WEBINAR = '/webinar/[webinarShortLink]';
export const SSR_PAGE_STUDENT_ASSIGNMENT =
'/students/[userId]/homeworks/[assignmentId]';
export const SSR_PAGE_CATEGORY = '/category/[categoryId]';
export const SSR_PAGE_LEARNING_PATH = '/learning-path/[shortLink]';
export const SSR_PAGE_LEARNING_AREA = '/learning-area/[shortLink]';
export const SSR_PAGE_BUSINESS_LOGIN = '/business-login';
export const SSR_PAGE_KNOWLEDGE_BASE = '/knowledge';

const SSR_PAGES = {
	[SSR_PAGE_HOME]: (draft, ssrState) => {
		draft.course.coursesView = ssrState.course.coursesView;
		draft.course.coursesViewState = ssrState.course.coursesViewState;
		draft.event.events = ssrState.event.events;
		draft.event.getEventsState = ssrState.event.getEventsState;
		draft.webinar.webinarsList = ssrState.webinar.webinarsList;
		draft.webinar.webinarsListState = ssrState.webinar.webinarsListState;
	},
	[SSR_PAGE_COURSE]: (draft, ssrState, currentState) => {
		draft.course.coursesView = ssrState.course.coursesView;
		draft.course.coursesViewState = ssrState.course.coursesViewState;
		// SSR 有時候會用沒有 Auth 的資料壓掉 Client 有 Auth 的資料，導致狀態顯示錯誤
		// ref: https://github.com/kirill-konshin/next-redux-wrapper#state-reconciliation-during-hydration
		draft.course.currentCourse = {
			...ssrState.course.currentCourse,
			bought: currentState?.course?.currentCourse?.bought,
			paid: currentState?.course?.currentCourse?.paid,
		};
		draft.course.currentCourseState = ssrState.course.currentCourseState;
		draft.course.currentCourseGeneralState = ssrState.course.currentCourseGeneralState;
		draft.course.getCourseRatingStates = ssrState.course.getCourseRatingStates;
		draft.course.courseRatings = ssrState.course.courseRatings;

		draft.comment.getDiscussionsBeforeStates =
      ssrState.comment.getDiscussionsBeforeStates;
		draft.comment.discussionsBefore = ssrState.comment.discussionsBefore;
		draft.comment.getDiscussionsStates = ssrState.comment.getDiscussionsStates;
		draft.comment.discussions = ssrState.comment.discussions;

		draft.announcement.announcements = ssrState.announcement.announcements;
		draft.announcement.getAnnouncementsStates =
      ssrState.announcement.getAnnouncementsStates;
	},
	[SSR_PAGE_STUDENT_ASSIGNMENT]: (draft, ssrState) => {
		draft.otherUser.otherUserProfile = ssrState.otherUser.otherUserProfile;
		draft.otherUser.otherUserProfileStates =
      ssrState.otherUser.otherUserProfileStates;

		draft.homework.getCourseHomeworkDetailStates =
      ssrState.homework.getCourseHomeworkDetailStates;
		draft.homework.homeworkDetail = ssrState.homework.homeworkDetail;
		draft.homework.getCourseHomeworkRepliesState =
      ssrState.homework.getCourseHomeworkRepliesState;
		draft.homework.courseHomeworkReplies =
      ssrState.homework.courseHomeworkReplies;
	},
	[SSR_PAGE_EVENT]: (draft, ssrState) => {
		draft.event.event = ssrState.event.event;
		draft.event.getEventState = ssrState.event.getEventState;
	},
	[SSR_PAGE_WEBINAR]: (draft, ssrState) => {
		draft.webinar.webinar = ssrState.webinar.webinar;
		draft.webinar.getWebinarState = ssrState.webinar.getWebinarState;
		draft.purchase.purchaseWebinars = ssrState.purchase.purchaseWebinars;
		draft.purchase.purchaseWebinarsState = ssrState.purchase.purchaseWebinarsState;
	},
	[SSR_PAGE_HOME]: (draft, ssrState) => {
		draft.course.coursesView = ssrState.course.coursesView;
	},
	[SSR_PAGE_CATEGORY]: (draft, ssrState) => {
		draft.category.categories = ssrState.category.categories;
		draft.category.hierarchy = ssrState.category.hierarchy;
		draft.category.search = ssrState.category.search;
	},
	[SSR_PAGE_LEARNING_PATH]: (draft, ssrState, currentState) => {
		draft.learningPath = {
			...ssrState.learningPath,
			learningPathsProgress: {
				...currentState.learningPath.learningPathsProgress,
			},
		};
	},
	[SSR_PAGE_LEARNING_AREA]: (draft, ssrState) => {
		draft.learningArea = ssrState.learningArea;
	},
	[SSR_PAGE_KNOWLEDGE_BASE]: (draft, ssrState) => {
		draft.knowledgeBasePage = ssrState.knowledgeBasePage;
	},
};

export default function updateHydrateState(currentState, ssrState) {
	const ssrPage = selectors.getSSRPage(ssrState);
	const updateHydrate = SSR_PAGES[ssrPage];

	const nextState = produce(currentState, draft => {
		defaultActionCalls.updateHydrateState(draft, ssrState);

		updateHydrate && updateHydrate(draft, ssrState, currentState);
	});

	return nextState;
}
