import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_SHIFT_CALL,
	POST_SHIFT_CALL,
	DELETE_SHIFT_CALL,
	GET_SHIFT_DETAIL_CALL,
	PATCH_SHIFT_CALL,
	GET_SHIFT_SCHEDULERS_SETTINGS_CALL,
	POST_SHIFT_SCHEDULERS_SETTINGS_CALL,
	GET_SHIFT_SCHEDULES_CALL,
	POST_SHIFT_SCHEDULES_CALL,
	DELETE_SHIFT_SCHEDULES_CALL,
	GET_SHIFT_SCHEDULES_DETAIL_CALL,
	PATCH_SHIFT_SCHEDULES_CALL,
	POST_SHIFT_SCHEDULES_ASSIGNMENTS_CALL,
	GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_CALL,
	GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_CALL,
	GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL,
	POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL,
	GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_CALL,
	POST_SHIFT_SCHEDULES_PUBLISH_CALL,
	GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_CALL,
	GET_MEMBERS_CALL,
} from '@src/store/types/shift';

import {
	getShiftDone,
	getShiftFail,
	postShiftDone,
	postShiftFail,
	deleteShiftDone,
	deleteShiftFail,
	getShiftDetailDone,
	getShiftDetailFail,
	patchShiftDone,
	patchShiftFail,
	getShiftSchedulersSettingsDone,
	getShiftSchedulersSettingsFail,
	postShiftSchedulersSettingsDone,
	postShiftSchedulersSettingsFail,
	getShiftSchedulesDone,
	getShiftSchedulesFail,
	postShiftSchedulesDone,
	postShiftSchedulesFail,
	deleteShiftSchedulesDone,
	deleteShiftSchedulesFail,
	getShiftSchedulesDetailDone,
	getShiftSchedulesDetailFail,
	patchShiftSchedulesDone,
	patchShiftSchedulesFail,
	postShiftSchedulesAssignmentsDone,
	postShiftSchedulesAssignmentsFail,
	getShiftSchedulesMyShiftsLeaveApplicationsDone,
	getShiftSchedulesMyShiftsLeaveApplicationsFail,
	getShiftSchedulesAssignmentsDraftDone,
	getShiftSchedulesAssignmentsDraftFail,
	postShiftSchedulesAssignmentsDraftDone,
	postShiftSchedulesAssignmentsDraftFail,
	getShiftSchedulesMyShiftsAssignmentsDone,
	getShiftSchedulesMyShiftsAssignmentsFail,
	getShiftSchedulesLeaveApplicationsDone,
	getShiftSchedulesLeaveApplicationsFail,
	postShiftSchedulesPublishDone,
	postShiftSchedulesPublishFail,
	getShiftSchedulesShiftEmployeesDone,
	getShiftSchedulesShiftEmployeesFail,
	getMembersDone,
	getMembersFail,
} from '@src/store/actions/shift';
import { toastShowCall } from '@src/store/actions/ui';
import {
	getShifts,
	postShifts,
	deleteShifts,
	getShiftDetail,
	patchShifts,
	getShiftSchedulersSettings,
	postShiftSchedulersSettings,
	getShiftSchedules,
	postShiftSchedules,
	deleteShiftSchedules,
	getShiftSchedulesDetail,
	patchShiftSchedules,
	postShiftSchedulesAssignments,
	getShiftSchedulesAssignmentsDraft,
	getShiftSchedulesMyShiftsAssignments,
	getShiftSchedulesLeaveApplications,
	getShiftSchedulesMyShiftsLeaveApplications,
	postShiftSchedulesAssignmentsDraft,
	postShiftSchedulesPublish,
	getShiftSchedulesShiftEmployees,
	getMembers,
} from '@src/services/shift';

import { TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@src/const/common';

export const getShiftsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_SHIFT_CALL),
	switchMap(
		({ payload }) => fromPromise(getShifts(payload)).pipe(
			switchMap(res => of(getShiftDone({ res }))),
			catchError(error => of(getShiftFail({ error }))),
		),
	),
);

export const postShiftsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_SHIFT_CALL),
	switchMap(
		({ payload }) => fromPromise(postShifts(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:created_success', type: TOAST_TYPE_SUCCESS }),
					postShiftDone({ res }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:created_fail', type: TOAST_TYPE_ERROR }),
				postShiftFail({ error }),
			)),
		),
	),
);

export const deleteShiftsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(DELETE_SHIFT_CALL),
	switchMap(
		({ payload }) => fromPromise(deleteShifts(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:deleted_success', type: TOAST_TYPE_SUCCESS }),
					deleteShiftDone({ res }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:deleted_fail', type: TOAST_TYPE_ERROR }),
				deleteShiftFail({ error }),
			)),
		),
	),
);

export const getShiftDetailEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_SHIFT_DETAIL_CALL),
	switchMap(
		({ payload }) => fromPromise(getShiftDetail(payload)).pipe(
			switchMap(res => of(getShiftDetailDone({ res }))),
			catchError(error => of(getShiftDetailFail({ error }))),
		),
	),
);

export const patchShiftsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(PATCH_SHIFT_CALL),
	switchMap(
		({ payload }) => fromPromise(patchShifts(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:updated_success', type: TOAST_TYPE_SUCCESS }),
					patchShiftDone({ res }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:updated_fail', type: TOAST_TYPE_ERROR }),
				patchShiftFail({ error }),
			)),
		),
	),
);

export const getShiftSchedulersSettingsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_SHIFT_SCHEDULERS_SETTINGS_CALL),
	switchMap(
		() => fromPromise(getShiftSchedulersSettings()).pipe(
			switchMap(res => of(getShiftSchedulersSettingsDone({ res }))),
			catchError(error => of(getShiftSchedulersSettingsFail({ error }))),
		),
	),
);

export const postShiftSchedulersSettingsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_SHIFT_SCHEDULERS_SETTINGS_CALL),
	switchMap(
		({ payload }) => fromPromise(postShiftSchedulersSettings(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:updated_success', type: TOAST_TYPE_SUCCESS }),
					postShiftSchedulersSettingsDone({ res }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:updated_fail', type: TOAST_TYPE_ERROR }),
				postShiftSchedulersSettingsFail({ error }),
			)),
		),
	),
);

export const getShiftSchedulesEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_SHIFT_SCHEDULES_CALL),
	switchMap(
		({ payload }) => fromPromise(getShiftSchedules(payload)).pipe(
			switchMap(res => of(getShiftSchedulesDone({ res }))),
			catchError(error => of(getShiftSchedulesFail({ error }))),
		),
	),
);

export const postShiftSchedulesEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_SHIFT_SCHEDULES_CALL),
	switchMap(
		({ payload }) => fromPromise(postShiftSchedules(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:created_success', type: TOAST_TYPE_SUCCESS }),
					postShiftSchedulesDone({ res }),
				);
			}),
			catchError(error => {
				if (payload.onError) payload.onError(error.response);
				return of(
					toastShowCall({ message: 'fail:created_fail', type: TOAST_TYPE_ERROR }),
					postShiftSchedulesFail({ error }),
				);
			}),
		),
	),
);

export const deleteShiftSchedulesEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(DELETE_SHIFT_SCHEDULES_CALL),
	switchMap(
		({ payload }) => fromPromise(deleteShiftSchedules(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:deleted_success', type: TOAST_TYPE_SUCCESS }),
					deleteShiftSchedulesDone({ res }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:deleted_fail', type: TOAST_TYPE_ERROR }),
				deleteShiftSchedulesFail({ error }),
			)),
		),
	),
);

export const getShiftSchedulesDetailEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_SHIFT_SCHEDULES_DETAIL_CALL),
	switchMap(
		({ payload }) => fromPromise(getShiftSchedulesDetail(payload)).pipe(
			switchMap(res => of(getShiftSchedulesDetailDone({ res }))),
			catchError(error => of(getShiftSchedulesDetailFail({ error }))),
		),
	),
);

export const patchShiftSchedulesEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(PATCH_SHIFT_SCHEDULES_CALL),
	switchMap(
		({ payload }) => fromPromise(patchShiftSchedules(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:updated_success', type: TOAST_TYPE_SUCCESS }),
					patchShiftSchedulesDone({ res }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:updated_fail', type: TOAST_TYPE_ERROR }),
				patchShiftSchedulesFail({ error }),
			)),
		),
	),
);

export const postShiftSchedulesAssignmentsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_SHIFT_SCHEDULES_ASSIGNMENTS_CALL),
	switchMap(
		({ payload }) => fromPromise(postShiftSchedulesAssignments(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:created_success', type: TOAST_TYPE_SUCCESS }),
					postShiftSchedulesAssignmentsDone({ res }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:created_fail', type: TOAST_TYPE_ERROR }),
				postShiftSchedulesAssignmentsFail({ error }),
			)),
		),
	),
);

export const getShiftSchedulesAssignmentsDraftEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL),
	switchMap(
		({ payload }) => fromPromise(getShiftSchedulesAssignmentsDraft(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(getShiftSchedulesAssignmentsDraftDone({ res }));
			}),
			catchError(error => of(getShiftSchedulesAssignmentsDraftFail({ error }))),
		),
	),
);

export const postShiftSchedulesAssignmentsDraftEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL),
	switchMap(
		({ payload }) => fromPromise(postShiftSchedulesAssignmentsDraft(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:created_success', type: TOAST_TYPE_SUCCESS }),
					postShiftSchedulesAssignmentsDraftDone({ res }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:created_fail', type: TOAST_TYPE_ERROR }),
				postShiftSchedulesAssignmentsDraftFail({ error }),
			)),
		),
	),
);

export const getShiftSchedulesLeaveApplicationsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_CALL),
	switchMap(
		({ payload }) => fromPromise(getShiftSchedulesLeaveApplications(payload)).pipe(
			switchMap(res => of(getShiftSchedulesLeaveApplicationsDone({ res }))),
			catchError(error => of(getShiftSchedulesLeaveApplicationsFail({ error }))),
		),
	),
);

export const getShiftSchedulesMyShiftsAssignmentsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_CALL),
	switchMap(
		({ payload }) => fromPromise(getShiftSchedulesMyShiftsAssignments(payload)).pipe(
			switchMap(res => of(getShiftSchedulesMyShiftsAssignmentsDone({ res }))),
			catchError(error => of(getShiftSchedulesMyShiftsAssignmentsFail({ error }))),
		),
	),
);

export const getShiftSchedulesMyShiftsLeaveApplicationsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_CALL),
	switchMap(
		({ payload }) => fromPromise(getShiftSchedulesMyShiftsLeaveApplications(payload)).pipe(
			switchMap(res => of(getShiftSchedulesMyShiftsLeaveApplicationsDone({ res }))),
			catchError(error => of(getShiftSchedulesMyShiftsLeaveApplicationsFail({ error }))),
		),
	),
);

export const postShiftSchedulesPublishEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_SHIFT_SCHEDULES_PUBLISH_CALL),
	switchMap(
		({ payload }) => fromPromise(postShiftSchedulesPublish(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:created_success', type: TOAST_TYPE_SUCCESS }),
					postShiftSchedulesPublishDone({ res }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:created_fail', type: TOAST_TYPE_ERROR }),
				postShiftSchedulesPublishFail({ error }),
			)),
		),
	),
);

export const getShiftSchedulesShiftEmployeesEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_CALL),
	switchMap(
		({ payload }) => fromPromise(getShiftSchedulesShiftEmployees(payload)).pipe(
			switchMap(res => of(getShiftSchedulesShiftEmployeesDone({ res }))),
			catchError(error => of(getShiftSchedulesShiftEmployeesFail({ error }))),
		),
	),
);

export const getMembersEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_MEMBERS_CALL),
	switchMap(
		({ payload }) => fromPromise(getMembers(payload)).pipe(
			switchMap(res => of(getMembersDone({ res }))),
			catchError(error => of(getMembersFail({ error }))),
		),
	),
);
