import axios from 'axios';
import { ui } from '@src/services';
import _ from 'lodash';

import { TOAST_TYPE_ERROR } from '@src/const/common';
import { checkIsServer } from '@src/utils/deprecatedCommon';

// axios.interceptors.request.use(
//   function (config) {
//     // Do something before request is sent

//     setTimeout(() => {
//       console.log(axios.defaults.headers.common);
//       console.log(config.headers.common);
//     }, 3000);

//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

const handleCommonError = _.debounce(err => {
	const { message, response, code } = err;

	console.error('handleCommonError', code, message);
	if (code === 'ECONNABORTED') {
		ui.toastShow({ message: 'errors:serverError', type: TOAST_TYPE_ERROR });
	} else if (message === 'Network Error') {
		ui.toastShow({ message: 'errors:networkError', type: TOAST_TYPE_ERROR });
	} else if (response) {
		const { status } = response;

		switch (status) {
			case 500:
				ui.toastShow({ message: 'errors:serverError', type: TOAST_TYPE_ERROR });
				break;
			case 502:
				ui.toastShow({ message: 'errors:serverError', type: TOAST_TYPE_ERROR });
				break;
			case 503:
				ui.toastShow({ message: 'errors:serverError', type: TOAST_TYPE_ERROR });
				break;
			default:
				break;
		}
	}
}, 300);

const request = (method, url, data, headers, otherConfigs = {}) => {
	if (!checkIsServer() && !window.navigator.onLine) {
		ui.toastShow({ message: 'errors:networkError', type: TOAST_TYPE_ERROR });

		return Promise.reject();
	}

	const { hasPagination, ssrHeaders = {}, customHeader = {}, ...remainingConfigs } = otherConfigs;
	let newHeaders = headers;

	if (!_.isEmpty(ssrHeaders) || !_.isEmpty(customHeader)) {
		newHeaders = {
			...headers,
			...ssrHeaders,
			...customHeader,
			Authorization: customHeader?.Authorization || headers?.Authorization || ssrHeaders?.Authorization || '',
		};
	}

	const param = {
		url,
		method,
		headers: newHeaders,
		data: method !== 'GET' ? data : null,
		params: method === 'GET' ? data : null,
		timeout: 15 * 1000,
		...remainingConfigs,
	};

	return axios(param)
		.then(res => {
			if (hasPagination) {
				const nextId = res.headers['x-next-id'];
				const totalCount = parseInt(res.headers['x-total-count'], 10);
				const pagination = {
					totalCount,
					...nextId ? { nextId } : {},
				};

				return Promise.resolve({
					data: res.data,
					pagination,
				});
			}

			return Promise.resolve(res.data);
		})
		['catch'](err => {
			if (axios.isCancel(err)) {
				// 被取消請求
			}

			handleCommonError(err);

			return Promise.reject(err);
		});
};

export default request;
