import { combineActions, handleActions } from 'redux-actions';
import produce from 'immer';
import * as types from '@src/store/types';
import _ from 'lodash';

const initialState = {
  showPageLoading: true,
  themes: {
    logo_link: {},
    icon_link: {},
    color_theme: 'default',
    enable_banner: true,
    banner: null,
    theme: null,
    disable_purchase: false,
    language_preference: null,
  },
  themeState: 'IDLE',
};

export default handleActions(
  {
    [types.GET_THEMES_DONE](state, { payload }) {
      return produce(state, (draft) => {
        draft.themeState = 'SUCCESS';
        draft.showPageLoading = false;
        draft.themes = payload;
      });
    },
    [types.GET_THEMES_CALL](state, { payload }) {
      return produce(state, (draft) => {
        draft.themeState = 'LOADING';
        draft.showPageLoading = !payload.disableLoading;
      });
    },
    [types.GET_THEMES_FAIL](state, { payload }) {
      return produce(state, (draft) => {
        if (payload?.response?.status === 403) {
          draft.themeState = 'NOT_FOUND';
        } else if (payload?.response?.status === 402) {
          draft.themeState = 'PAYMENT_REQUIRED';
        } else {
          draft.themeState = 'FAIL';
        }
      });
    },
    [types.POST_USER_LANGUAGE_DONE](state, { payload }) {
      return produce(state, (draft) => {
        draft.themes.language_preference = payload.language;
      });
    },
  },
  initialState
);
