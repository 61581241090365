// eslint-disable-next-line import/no-cycle
import api from '@src/api';
import { Observable } from 'rxjs';
import { Action } from 'redux-actions';

export const getShifts = (payload: {
	page: number;
	page_size: number;
}): Observable<Action> => (
	api.get('shifts/', payload, {}, { hasPagination: true })
);

export const postShifts = (payload): Observable<Action> => (
	api.post('shifts/', payload)
);

export const deleteShifts = (payload: { id: string }): Observable<Action> => (
	api['delete'](`shifts/${payload.id}`)
);

export const getShiftDetail = (payload: { id: string }): Observable<Action> => (
	api.get(`shifts/${payload.id}`)
);

export const patchShifts = (payload): Observable<Action> => (
	api.patch(`shifts/${payload.id}`, payload)
);

export const getShiftSchedulersSettings = (): Observable<Action> => (
	api.get('shift-schedulers/settings')
);

export const postShiftSchedulersSettings = (payload): Observable<Action> => (
	api.post('shift-schedulers/settings', payload)
);

export const getShiftSchedules = (payload: {
	page: number;
	page_size: number;
}): Observable<Action> => (
	api.get('shift-schedules/', payload, { hasPagination: true })
);

export const postShiftSchedules = (payload): Observable<Action> => (
	api.post('shift-schedules/', payload)
);

export const deleteShiftSchedules = (payload: { id: string }): Observable<Action> => (
	api['delete'](`shift-schedules/${payload.id}`)
);

export const getShiftSchedulesDetail = (payload: { id: string }): Observable<Action> => (
	api.get(`shift-schedules/${payload.id}`)
);

export const patchShiftSchedules = (payload): Observable<Action> => (
	api.patch(`shift-schedules/${payload.id}`, payload)
);

export const postShiftSchedulesAssignments = (payload): Observable<Action> => (
	api.post(`shift-schedules/${payload.schedule_id}/assignments`, payload)
);

export const getShiftSchedulesAssignmentsDraft = (payload: { schedule_id: string }): Observable<Action> => (
	api.get(`shift-schedules/${payload.schedule_id}/assignments/draft`)
);

export const getShiftSchedulesLeaveApplications = (payload: { schedule_id: string }): Observable<Action> => (
	api.get(`shift-schedules/${payload.schedule_id}/leave-applications`)
);

export const getShiftSchedulesMyShiftsAssignments = (payload): Observable<Action> => (
	api.get('shift-schedules/my-shifts/assignments', payload)
);

export const getShiftSchedulesMyShiftsLeaveApplications = (payload): Observable<Action> => (
	api.get('shift-schedules/my-shifts/leave-applications', payload)
);

export const postShiftSchedulesAssignmentsDraft = (payload): Observable<Action> => (
	api.post(`shift-schedules/${payload.schedule_id}/assignments/draft`, payload.assignments)
);

export const postShiftSchedulesPublish = (payload: { schedule_id: string }): Observable<Action> => (
	api.post(`shift-schedules/${payload.schedule_id}/publish`)
);

export const getShiftSchedulesShiftEmployees = (payload: { schedule_id: string }): Observable<Action> => (
	api.get(`shift-schedules/${payload.schedule_id}/shift-employees`)
);

export const getMembers = (payload): Observable<Action> => (
	api.get('members', payload)
);
