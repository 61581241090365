import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import dayjs from 'dayjs';
import useTranslation from '@src/hooks/useTranslation';

import * as actions from '@src/store/actions';
import Button from '@src/components/common/button';
import {
	BlankUserIcon,
	EmailOutlinedIcon,
	GoogleWhiteCircleIcon,
	FacebookWhiteCircleIcon,
	LineWhiteCircleIcon,
} from '@src/components/common/icon';
import {
	Wrapper, Content,
} from './Styled';

const Header = styled.div`
	color: #333;
	font-weight: 500;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const EmailInfo = styled.div`
	color: #222;
	font-weight: 500;
	font-size: 14px;
	margin-top: 30px;
`;

const ProfileBox = styled.div`
	border: 1px solid #DBDBDB;
	border-radius: 2px;
	padding: 30px 20px;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const UserName = styled.div`
	margin-top: 15px;
	font-size: 16px;
	color: #101010;
`;

const CreatedAt = styled.div`
	margin-top: 5px;
	color: #9C9C9C;
	font-size: 14px;
`;

const Avatar = styled.img`
	width: 60px;
	height: 60px;
	border-radius: 100%;
`;

const SubmitButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 46px;
	margin-top: 25px;
	.submit-button__text {
		margin-left: 12px;
	}
`;

const LoginWithOtherAccount = styled.div`
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: ${props => props.theme.colors.primary};
`;

const ConflictEmailModal = ({
	onClose,
}: {
	onClose: () => void;
}): JSX.Element => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [targetClass, setTargetClass] = useState();
	const router = useRouter();
	const { query } = router || {};
	const {
		email,
		name,
		created_at: createdAt,
		provider,
		avatar_link: avatarLink,
	} = query || {};
	const formattedCreatedAt = dayjs(Number(createdAt)).locale('zh-tw').format('YYYY-MM-DD (dd)');
	const makeSubmitButton = () => {
		if (provider === 'email') {
			return {
				buttonText: t('conflict_email:submit_button_text_general'),
				buttonIcon: <EmailOutlinedIcon />,
				handleOnClick: handleOpenLoginModal,
			};
		}
		if (provider === 'google') {
			return {
				buttonText: t('conflict_email:submit_button_text', { provider: 'Google' }),
				buttonIcon: <GoogleWhiteCircleIcon />,
				handleOnClick: handleLoginByProvider,
			};
		}
		if (provider === 'facebook') {
			return {
				buttonText: t('conflict_email:submit_button_text', { provider: 'Facebook' }),
				buttonIcon: <FacebookWhiteCircleIcon />,
				handleOnClick: handleLoginByProvider,
			};
		}
		if (provider === 'line') {
			return {
				buttonText: t('conflict_email:submit_button_text', { provider: 'Line' }),
				buttonIcon: <LineWhiteCircleIcon />,
				handleOnClick: handleLoginByProvider,
			};
		}
	};

	const handleClickStart = e => {
		setTargetClass(e.target.className);
	};

	const handleClickEnd = e => {
		if (
			e.target.id === 'modal-wrap' &&
			window.innerWidth > 768 &&
			targetClass === e.target.className
		) {
			onClose();
		}
		setTargetClass(null);
	};

	const handleOpenLoginModal = () => {
		router.push('/training');
		dispatch(actions.setModalType({ type: 'login' }));
	};

	const handleLoginByProvider = () => {
		dispatch(actions.socialLoginPrepareCall({ name: provider }));
	};

	const {
		buttonText,
		buttonIcon,
		handleOnClick,
	} = makeSubmitButton();

	return (
		<Wrapper
			onMouseDown={e => handleClickStart(e)}
			onMouseUp={e => handleClickEnd(e)}
			id="modal-wrap"
		>
			<Content onClick={e => e.stopPropagation()}>
				<Header>
					<span>{t('conflict_email:conflict_email')}</span>
				</Header>
				<EmailInfo>
					{t('conflict_email:email_info', { email })}
				</EmailInfo>
				<ProfileBox>
					{
						avatarLink ? (
							<Avatar src={avatarLink} alt="" />
						) : (
							<BlankUserIcon />
						)
					}
					<UserName>{name}</UserName>
					<CreatedAt>{t('conflict_email:created_at', { createdAt: formattedCreatedAt })}</CreatedAt>
					<SubmitButton onClick={handleOnClick}>
						{buttonIcon}
						<div className="submit-button__text">{buttonText}</div>
					</SubmitButton>
				</ProfileBox>
				<LoginWithOtherAccount onClick={handleOpenLoginModal}>
					<span>{t('conflict_email:using_other_account_to_login')}</span>
				</LoginWithOtherAccount>
			</Content>
		</Wrapper>
	);
};

export default ConflictEmailModal;
